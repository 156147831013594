<template>
  <HeaderComponent :key="onCookiebotDeclined" />
  <main id="main">
    <RouterView />
    <ComparisonBar />
  </main>
  <SuccessModal :is-open="successModal.show" :item="successModal" @close="closeSuccessModal" />
</template>

<script>
import { RouterView } from 'vue-router'
import { langMixin } from '@/lang.js'
import HeaderComponent from '@/components/Header/Header.vue'
import { computed } from 'vue'
import { querierMixin } from '@/lib/querierMixin'
import SuccessModal from '@/components/SuccessModal.vue'
import ComparisonBar from '@/components/Comparison/ComparisonBar.vue'

export default {
  name: 'RootApp',
  components: { RouterView, HeaderComponent, SuccessModal, ComparisonBar },
  mixins: [langMixin('Common'), querierMixin],
  provide() {
    return {
      currentUser: computed(() => this.currentUser),
      targetSiteId: computed(() => this.targetSiteId),
      setCurrentUser: newValue => {
        this.currentUser = newValue
      },
      showSuccessModal: this.showSuccessModal
    }
  },
  data() {
    return {
      SDKLoaded: false,
      compareIds: '',
      currentUser: null,
      onCookiebotDeclined: false,
      targetSiteId: null,
      successModal: {
        show: false,
        title: '',
        subtitle: ''
      }
    }
  },
  created() {
    window.seezSdk?.getCurrentUser().then(r => (this.currentUser = r))
  },
  mounted() {
    this.onCookieBotActions()
    this.loadCookiebot()
    this.getTargetSiteId()
    this.loadSDK()
  },
  methods: {
    async loadSDK() {
      const components = ['listing-details','search', 'favorites', 'saved-searches', 'carousel', 'trade-in', 'orders']
      const promises = components.map(c => window.customElements.whenDefined('seez-sdk-' + c))
      await Promise.allSettled(promises)
      window.seezSdk.getCurrentUser().then(user => (this.user = user))
      this.SDKLoaded = true
    },
    updateCompareIds(listingId) {
      // Split the compareIds string into an array
      let idsArray = this.compareIds ? this.compareIds.split(',') : []
      if (idsArray.includes(listingId)) {
        idsArray = idsArray.filter(item => item !== listingId)
      } else {
        if (idsArray.length < 3) {
          idsArray.push(listingId)
        }
      }

      this.compareIds = idsArray.join(',')
    },
    //converts a string into something healthy for urls
    slugify(text) {
      return text
        ?.trim()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .replace(/[^\w]+/g, '-')
        .replace(/-+$/g, '')
        .toLowerCase()
    },
    async getTargetSiteId() {
      try {
        const response = await this.queryApi(`
        {
          targetSiteByClientId(id: "bar") {
            id
          }
        }
        `)

        this.targetSiteId = response?.targetSiteByClientId?.id
      } catch (e) {
        //
      }
    },
    login() {
      window.seezSdk.showLogin(null, true, {
        branding: '<div class="branding">&nbsp;</div>',
        terms: this.languageResources?.Profile?.termPolicy,
        marketing: this.languageResources?.Profile?.marketingTerms
      })
    },
    loadCookiebot() {
      if (import.meta.env.VITE_COOKIEBOT_ID == null || import.meta.env.VITE_ENV_LABEL === 'local') return

      const script = document.createElement('script')
      script.id = 'Cookiebot'
      script.src = 'https://consent.cookiebot.com/uc.js'
      script.dataset.cbid = import.meta.env.VITE_COOKIEBOT_ID
      script.dataset.blockingmode = 'auto'
      script.type = 'text/javascript'
      script.async = true
      script.setAttribute('data-culture', this.language)

      document.head.appendChild(script)
    },
    onCookieBotActions() {
      window.addEventListener('CookiebotOnDecline', () => {
        console.log('Cookiebot decline detected. Reloading some components.')
        this.onCookiebotDeclined = !this.onCookiebotDeclined
      })
    },
    closeSuccessModal() {
      this.successModal = {
        show: false
      }
    },
    showSuccessModal(data) {
      this.successModal = {
        show: true,
        ...data
      }
    }
  }
}
</script>

<style lang="scss">
#app {
  display: grid;
  grid-template:
    'header' auto
    'content' 1fr
    'footer' auto;

  > header {
    grid-area: header;
    gap: 1rem;
    padding: 1rem;
    background-color: var(--dark-gray);
    box-shadow: var(--shadow);
    place-items: center;
  }

  > main {
    grid-area: content;
    overflow: auto;
  }

  > footer {
    grid-area: footer;
    padding: 1rem;
    box-shadow: var(--shadow);
    background-color: var(--background);

    &:empty {
      display: none;
    }
  }
}

seez-sdk-login-form {
  .seezLogin {
    --highlight: var(--primary);
    --font-family: var(--base-font);
    --base-font: var(--base-font);

    @media screen and (min-width: 50rem) {
      margin: -1rem;
    }

    .loginForm {
      input,
      button,
      > .socialWrapper > div > button.social {
        border-radius: 0.75rem;
      }

      h1 {
        font-family: var(--heading-font);
      }
      button {
        background-color: var(--accent);
      }
    }
  }

  .branding {
    background-color: #1c2021;
    background-image: url('/assets/hb-logo.svg'), url('/assets/hey.svg'), url('/assets/hey.svg'), url('/assets/hey.svg'), url('/assets/hey.svg');
    background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat;
    background-position:
      50% 15%,
      -40% 65%,
      -194% 94%,
      856% 57%,
      838% 107%;
    background-size: 66%, 90%, 90%, 90%, 90%;
    border-radius: 0;
    width: 18rem;
    height: 100%;
  }

  [slot='terms'] {
    display: inline;

    a {
      color: black;
      font-weight: bold;
    }
  }
  [slot='marketing'] {
    display: inline;
  }
}
</style>
